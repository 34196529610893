import { Box, Tab, Tabs } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useDispatch } from "react-redux";

import { isClientAdmin } from "~/api/usersTypes/auth0Profile";
import { useAppSelector } from "~/app/store";
import { navBarHeight } from "~/components/navbar/Navbar";

import { useDevCheats } from "~/hooks/useDevCheats";
import { useNavbar } from "~/hooks/useNavbar";
import { useTabNavLocation } from "~/hooks/useTabNavLocation";
import { useView } from "~/hooks/useView";
import { resetSiteWorkstationStatus } from "~/redux/actions/workstations";
import { StoreState } from "~/redux/reducers";

import { useGetWorkstationsQuery } from "~/redux/warehouse/workstation.hooks";

import AdminSummariesTab from "./AdminSummariesTab";
import AndonBoard from "./AndonBoard";
import AndonCompactFullscreen from "./AndonCompactFullscreen";
import AndonCompactSetupTab from "./AndonCompactSetupTab";
import {
  clearAndonWorkstationsAndGrids,
  clearEventData,
  setAndonViewOption,
  selectCurrentAndonBoardView,
  selectFetchWorkstationsInterval
} from "./andon.slice";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";

const mapStateToProps = (state: StoreState) => ({
  usersFcId: state.store.usersFulfillmentCenter?.fulfillmentCenterId || null,
  isAdmin: state.login.profile ? isClientAdmin(state.login.profile) : false
});

const connector = connect(mapStateToProps, {
  resetSiteWorkstationStatus
});

type PropsFromRedux = ConnectedProps<typeof connector>;
type AndonProps = PropsFromRedux;

export function Andon(props: AndonProps) {
  const { isAdmin } = props;

  const { t } = useTranslation();
  const fetchWorkstationsInterval = useAppSelector(
    selectFetchWorkstationsInterval
  );
  const dispatch = useDispatch();

  const { refetch: refetchWorkstations, error: getWorkstationsError } =
    useGetWorkstationsQuery(undefined, {
      refetchOnMountOrArgChange: true,
      skipPollingIfUnfocused: true,
      pollingInterval: fetchWorkstationsInterval || 0
    });
  if (getWorkstationsError) {
    throw new Error(getMessageFromRtkError(getWorkstationsError));
  }

  const validTabs = ["andon-board", "andon-compact", "admin-summaries"];
  const { tabIndex, handleTabIndexChange } = useTabNavLocation({
    defaultTab: "andon-board",
    validTabs,
    key: "andon-tab"
  });

  const andonViewOption = useAppSelector(selectCurrentAndonBoardView);
  const isFullScreen = ["compact fullscreen", "board fullscreen"].includes(
    andonViewOption
  );
  useView({ permanentSidenav: !isFullScreen });
  const { setMenuItems } = useNavbar({
    isHidden: isFullScreen,
    viewTitle: t("Andon")
  });
  useDevCheats({ showEventSimulator: true });

  useEffect(() => {
    setMenuItems([
      {
        textContent: "Clear Andon Statuses and Fetch Workstations",
        actionCb: () => {
          dispatch(clearAndonWorkstationsAndGrids());
          void refetchWorkstations();
          props.resetSiteWorkstationStatus();
        }
      },
      {
        textContent: "Clear Events",
        actionCb: () => {
          dispatch(clearEventData());
        }
      }
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <Box
        id="andon-container"
        sx={{
          width: "100%",
          height: isFullScreen ? "100vh" : `calc(100vh - ${navBarHeight})`,
          paddingLeft: isFullScreen ? 0 : undefined,
          paddingRight: isFullScreen ? 0 : undefined
        }}
        onClick={() => {
          if (isFullScreen) dispatch(setAndonViewOption("default"));
        }}
        role="button"
        aria-label="exit full screen"
        tabIndex={0}
        onKeyDown={(event) => {
          if ((isFullScreen && event.key === "Enter") || event.key === " ") {
            event.preventDefault();
            dispatch(setAndonViewOption("default"));
          }
        }}
      >
        {!isFullScreen && (
          <>
            <Box
              id="tabs-and-buttons"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Tabs
                value={tabIndex}
                onChange={(_event, newValue) =>
                  handleTabIndexChange(newValue as string)
                }
                aria-label="status tabs"
                sx={{
                  mt: 1,
                  mb: 2,
                  ml: 2
                }}
              >
                <Tab label={t("andon board")} value="andon-board" />
                <Tab label={t("andon compact")} value="andon-compact" />
                {isAdmin && (
                  <Tab label={t("admin summaries")} value="admin-summaries" />
                )}
              </Tabs>
            </Box>
            <Box
              id="tab-content"
              sx={{
                height: "90%",
                width: "100%",
                overflowX: "hidden"
              }}
            >
              {tabIndex === "andon-compact" && <AndonCompactSetupTab />}
              {tabIndex === "andon-board" && <AndonBoard />}
              {tabIndex === "admin-summaries" && <AdminSummariesTab />}
            </Box>
          </>
        )}

        {/* full screen version */}
        {andonViewOption === "compact fullscreen" && <AndonCompactFullscreen />}
        {andonViewOption === "board fullscreen" && <AndonBoard />}
      </Box>
    </>
  );
}

export default connector(Andon);
