import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import ReactJson from "react-json-view";

import { useAppSelector } from "~/app/store";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";

export const FcConfigList = () => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const usersFulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);

  function ConfigSection({
    title,
    availableConfig,
    selectedConfig
  }: {
    title: string;
    availableConfig: string[];
    selectedConfig?: string[];
  }) {
    return (
      <Box sx={{ marginLeft: "10px" }}>
        <Box>
          <Typography variant="h6">{title}</Typography>
        </Box>
        <Grid container direction="row" sx={{ marginBottom: "10px" }}>
          {availableConfig.map((el) => {
            let matchingConfig = false;
            selectedConfig?.forEach((sc) => {
              if (sc.toLocaleLowerCase() === el) matchingConfig = true;
            });

            return (
              <Box
                key={el}
                style={{
                  margin: "5px",
                  padding: "10px",
                  backgroundColor: matchingConfig
                    ? palette.success.main
                    : "gray.dark"
                }}
              >
                <Typography>{el}</Typography>
              </Box>
            );
          })}
        </Grid>
      </Box>
    );
  }

  return (
    <Box padding={2} mx={{ display: "flex" }} data-testid="fc-config-tab">
      <Box sx={{ width: "50%", padding: "20px" }}>
        <Box sx={{ backgroundColor: "gray.light" }}>
          {usersFulfillmentCenter && (
            <Box data-testid="fc-config-container">
              <ReactJson
                displayDataTypes={false}
                quotesOnKeys={false}
                src={usersFulfillmentCenter}
              />
            </Box>
          )}
          ;
        </Box>
      </Box>

      <Box sx={{ width: "50%", padding: "20px" }}>
        <Paper sx={{ padding: "10px" }}>
          <ConfigSection
            title={t("picking configurations")}
            availableConfig={["manual", "autostore"]}
            selectedConfig={usersFulfillmentCenter?.pickingConfigurations}
          />
          <ConfigSection
            title={t("batch configuration")}
            availableConfig={[
              "calendar schedule",
              "recurring schedule",
              "manual"
            ]}
            selectedConfig={[usersFulfillmentCenter?.batchConfiguration || ""]}
          />
          <ConfigSection
            title={t("inventory date configuration")}
            availableConfig={["manufacturedate", "expirationdate"]}
            selectedConfig={[
              usersFulfillmentCenter?.inventoryDateConfiguration || ""
            ]}
          />
          <ConfigSection
            title={t("pick replacement configuration")}
            availableConfig={["prefer less bins", "disabled", "only autostore"]}
            selectedConfig={[
              usersFulfillmentCenter?.pickReplacementConfiguration || ""
            ]}
          />
          <ConfigSection
            title={t("putaway label configuration")}
            availableConfig={["automatic", "disabled"]}
            selectedConfig={[
              usersFulfillmentCenter?.putAwayLabelConfiguration || ""
            ]}
          />
          <ConfigSection
            title={t("replenishment configuration")}
            availableConfig={["automatic", "disabled"]}
            selectedConfig={[
              usersFulfillmentCenter?.replenishmentConfiguration || ""
            ]}
          />
          <ConfigSection
            title={t("store configuration")}
            availableConfig={["untrackedinventory", "disabled"]}
            selectedConfig={[usersFulfillmentCenter?.storeConfiguration || ""]}
          />
          <ConfigSection
            title={t("tote configuration")}
            availableConfig={["generatedtotes", "externaltotes", "nototes"]}
            selectedConfig={[usersFulfillmentCenter?.toteConfiguration || ""]}
          />
          <ConfigSection
            title={t("wave completion configuration")}
            availableConfig={["batchpicked", "totesverified"]}
            selectedConfig={[
              usersFulfillmentCenter?.waveCompletionConfiguration || ""
            ]}
          />
          <ConfigSection
            title={t("printer configuration")}
            availableConfig={["downloadzpl", "browserprint", "pageprint"]}
            selectedConfig={[
              usersFulfillmentCenter?.printerConfiguration || ""
            ]}
          />
        </Paper>
      </Box>
    </Box>
  );
};
