import Box from "@mui/material/Box";
import { useFlags, camelCaseKeys } from "launchdarkly-react-client-sdk";
import ReactJson from "react-json-view";

export const LaunchDarklyFlags = () => {
  const flags = camelCaseKeys(useFlags());

  return (
    <Box
      padding={2}
      mx={{ display: "flex" }}
      data-testid="launchdarkly-flags-tab"
    >
      <Box sx={{ width: "50%", padding: "20px" }}>
        <Box sx={{ backgroundColor: "gray.light" }}>
          {flags && (
            <Box data-testid="launchdarkly-flags-container">
              <ReactJson
                displayDataTypes={false}
                quotesOnKeys={false}
                src={flags}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
