import { Typography, InputLabel, Input, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { useDebounce } from "~/hooks/useDebounce";

import { useToast } from "~/hooks/useToast";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { selectWorkstationAutostoreGridId } from "~/redux/selectors/workstationsSelectors";
import {
  useEditCleaningScheduleMutation,
  useGetAutostoreGridQuery
} from "~/redux/warehouse/autostoreGrid.hooks";

function BinCleaning() {
  const { t } = useTranslation();

  const { successToast, errorToast } = useToast();

  const selectedAutostoreGridId = useAppSelector(
    selectWorkstationAutostoreGridId
  );

  const { data: selectedAutostoreGrid, isFetching } = useGetAutostoreGridQuery(
    selectedAutostoreGridId ?? skipToken
  );

  const [editCleaningSchedule] = useEditCleaningScheduleMutation();

  const [cleaningTasksState, setCleaningTasksState] = useState({
    minCleaningTasks: selectedAutostoreGrid?.minCleaningTasks ?? null,
    maxCleaningTasks: selectedAutostoreGrid?.maxCleaningTasks ?? null
  });

  useEffect(() => {
    if (!!selectedAutostoreGrid && !isFetching) {
      setCleaningTasksState({
        minCleaningTasks: selectedAutostoreGrid.minCleaningTasks ?? null,
        maxCleaningTasks: selectedAutostoreGrid.maxCleaningTasks ?? null
      });
    }
  }, [isFetching, selectedAutostoreGrid]);

  const updateGrid = useCallback(async () => {
    if (!cleaningTasksState || isFetching) return;

    const { minCleaningTasks, maxCleaningTasks } = cleaningTasksState;

    const shouldUpdate =
      selectedAutostoreGrid?.autostoreGridId &&
      (minCleaningTasks !== (selectedAutostoreGrid.minCleaningTasks ?? null) ||
        maxCleaningTasks !== (selectedAutostoreGrid.maxCleaningTasks ?? null));

    if (shouldUpdate) {
      try {
        await editCleaningSchedule({
          autostoreGridId: selectedAutostoreGrid?.autostoreGridId,
          minCleaningTasks: minCleaningTasks,
          maxCleaningTasks: maxCleaningTasks,
          cleaningCadenceInDays: null,
          maxBinFlaggedInventoryMoveTaskGroupSize: null
        }).unwrap();
        successToast("Successfully updated cleaning tasks");
      } catch (err) {
        errorToast(getMessageFromRtkError(err));
      }
    }
  }, [
    cleaningTasksState,
    selectedAutostoreGrid,
    isFetching,
    editCleaningSchedule,
    errorToast,
    successToast
  ]);

  // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
  useDebounce(updateGrid, 1000);

  return (
    <Stack direction="row" spacing={2} p="50px" alignItems="flex-end">
      <Typography sx={{ fontSize: "19px" }}>{t("bin maintenance")}</Typography>
      <InputLabel htmlFor="min-cleaning-tasks">
        <Typography>{t("min cleaning tasks")}</Typography>
      </InputLabel>
      <Input
        sx={{ m: 1 }}
        id="min-cleaning-tasks"
        type="number"
        inputProps={{ min: 0 }}
        value={cleaningTasksState.minCleaningTasks || ""}
        onChange={(e) => {
          setCleaningTasksState({
            ...cleaningTasksState,
            minCleaningTasks: e.target.value ? Number(e.target.value) : null
          });
        }}
      />
      <InputLabel htmlFor="max-cleaning-tasks">
        <Typography>{t("max cleaning tasks")}</Typography>
      </InputLabel>
      <Input
        sx={{ m: 1 }}
        id="max-cleaning-tasks"
        type="number"
        inputProps={{ min: 0 }}
        value={cleaningTasksState.maxCleaningTasks || ""}
        onChange={(e) => {
          setCleaningTasksState({
            ...cleaningTasksState,
            maxCleaningTasks: e.target.value ? Number(e.target.value) : null
          });
        }}
      />
    </Stack>
  );
}

export default BinCleaning;
