import { Typography, InputLabel, Input, Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { useDebounce } from "~/hooks/useDebounce";

import { useToast } from "~/hooks/useToast";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { selectWorkstationAutostoreGridId } from "~/redux/selectors/workstationsSelectors";
import {
  useEditCleaningScheduleMutation,
  useGetAutostoreGridQuery
} from "~/redux/warehouse/autostoreGrid.hooks";

function BinMovements() {
  const { t } = useTranslation();

  const { successToast, errorToast } = useToast();

  const selectedAutostoreGridId = useAppSelector(
    selectWorkstationAutostoreGridId
  );

  const { data: selectedAutostoreGrid, isFetching } = useGetAutostoreGridQuery(
    selectedAutostoreGridId ?? skipToken
  );

  const [editCleaningSchedule] = useEditCleaningScheduleMutation();

  const [moveTaskQuantityState, setMoveTaskQuantityState] = useState(
    selectedAutostoreGrid?.maxBinFlaggedInventoryMoveTaskGroupSize ?? null
  );

  useEffect(() => {
    if (!!selectedAutostoreGrid && !isFetching) {
      setMoveTaskQuantityState(
        selectedAutostoreGrid.maxBinFlaggedInventoryMoveTaskGroupSize ?? null
      );
    }
  }, [isFetching, selectedAutostoreGrid]);

  const updateGrid = useCallback(async () => {
    if (!moveTaskQuantityState || isFetching) return;

    const shouldUpdate =
      selectedAutostoreGrid?.autostoreGridId &&
      moveTaskQuantityState !==
        (selectedAutostoreGrid.maxBinFlaggedInventoryMoveTaskGroupSize ?? null);

    if (shouldUpdate) {
      try {
        await editCleaningSchedule({
          autostoreGridId: selectedAutostoreGrid?.autostoreGridId,
          minCleaningTasks: null,
          maxCleaningTasks: null,
          cleaningCadenceInDays: null,
          maxBinFlaggedInventoryMoveTaskGroupSize: moveTaskQuantityState
        }).unwrap();
        successToast("Successfully updated move tasks quantity");
      } catch (err) {
        errorToast(getMessageFromRtkError(err));
      }
    }
  }, [
    moveTaskQuantityState,
    selectedAutostoreGrid,
    isFetching,
    editCleaningSchedule,
    errorToast,
    successToast
  ]);

  // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
  useDebounce(updateGrid, 1000);

  return (
    <Stack direction="row" p="50px" spacing={2} alignItems="flex-end">
      <Typography sx={{ fontSize: "19px" }}>{t("bin movements")}</Typography>
      <InputLabel htmlFor="move-tasks-quantity">
        <Typography>{t("move tasks quantity")}</Typography>
      </InputLabel>
      <Input
        sx={{ m: 1 }}
        id="move-tasks-quantity"
        type="number"
        inputProps={{ min: 0 }}
        value={moveTaskQuantityState || ""}
        onChange={(e) => {
          setMoveTaskQuantityState(
            e.target.value ? Number(e.target.value) : null
          );
        }}
      />
    </Stack>
  );
}

export default BinMovements;
