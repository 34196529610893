import { Box, Tab, Tabs } from "@mui/material";

import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import Andon from "~/features/andon/Andon";
import Events from "~/features/andon/Events";
import { selectIsAndonBoardFullScreen } from "~/features/andon/andon.slice";
import CleaningBinSchedule from "~/features/cleaningBin/CleaningBinSchedule";
import { Status } from "~/features/status/Status";
import { WorkstationConfig } from "~/features/workstationConfig/WorkstationConfig";
import { useNavbar } from "~/hooks/useNavbar";
import { useTabNavLocation } from "~/hooks/useTabNavLocation";
import { useView } from "~/hooks/useView";

import { selectUserIsAdmin } from "~/redux/selectors/authSelectors";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import { selectIfWorkstationsTabShouldBeEnabled } from "~/redux/selectors/workstationsSelectors";

import { BatchSettings } from "./BatchSettings";
import FCReference from "./FCReference";
import { GridSettings } from "./GridSettings";
import Settings from "./Settings";

type SettingsViewInheritedProps = { viewTitle?: string };
type SettingsViewProps = SettingsViewInheritedProps;

function SettingsView(props: SettingsViewProps) {
  const { viewTitle } = props;

  const { t } = useTranslation();
  const shouldEnableWorkstationTab = useAppSelector(
    selectIfWorkstationsTabShouldBeEnabled
  );
  const selectedFulfillmentCenter = useAppSelector(
    selectUsersFulfillmentCenter
  );
  const clientConfig = useAppSelector(selectClientConfig);
  const isAdmin = useAppSelector(selectUserIsAdmin);
  const isAndonBoardFullScreen = useAppSelector(selectIsAndonBoardFullScreen);

  const {
    binCleaningEnabled,
    an_andonFeaturesEnabled,
    an_displayEventFeatures
  } = clientConfig;

  const binFlaggingEnabled = selectedFulfillmentCenter?.binFlaggingEnabled;
  const isBinCleaningTabEnabled =
    binCleaningEnabled && isAdmin && binFlaggingEnabled;

  useView({ permanentSidenav: true, fixedHeight: true });
  useNavbar({ viewTitle });

  const validTabs = [
    "settings",
    ...(shouldEnableWorkstationTab ? ["workstation-config"] : []),
    ...(isBinCleaningTabEnabled ? ["bin-cleaning-schedule"] : []),
    ...(an_andonFeaturesEnabled ? ["andon"] : []),
    ...(an_displayEventFeatures ? ["events"] : []),
    ...(isAdmin ? ["grid-settings", "batch-settings", "status"] : [])
  ];

  const { tabIndex, handleTabIndexChange } = useTabNavLocation({
    defaultTab: "settings",
    validTabs,
    key: "settings-tab",
    subTabs: [
      {
        subTab: "status",
        subTabKey: "status-tab",
        subTabDefault: "task-assessment"
      },
      {
        subTab: "events",
        subTabKey: "events-tab",
        subTabDefault: "event-explorer"
      },
      {
        subTab: "andon",
        subTabKey: "andon-tab",
        subTabDefault: "andon-board"
      }
    ]
  });

  const isCurrentTabAndonAndIsFullscreenMode =
    tabIndex === "andon" && isAndonBoardFullScreen;

  return (
    <Box
      id="settings-view"
      sx={{
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        height: "99%",
        padding: "1%"
      }}
    >
      {!isCurrentTabAndonAndIsFullscreenMode && <FCReference />}
      {!isCurrentTabAndonAndIsFullscreenMode && (
        <Tabs
          value={tabIndex}
          onChange={(_event, newValue) =>
            handleTabIndexChange(newValue as string)
          }
          aria-label="settings tabs"
        >
          <Tab label={t("settings")} value="settings" />
          {shouldEnableWorkstationTab && (
            <Tab label={t("workstations")} value="workstation-config" />
          )}

          {isAdmin && (
            <Tab
              label={`${t("batch")} ${t("nav.viewname.settings")}`}
              value="batch-settings"
            />
          )}
          {isBinCleaningTabEnabled && (
            <Tab
              label={t("nav.viewname.bin cleaning schedule")}
              value="bin-cleaning-schedule"
            />
          )}
          {isAdmin && <Tab label={t("grid settings")} value="grid-settings" />}
          {isAdmin && <Tab label={t("status")} value="status" />}
          {an_displayEventFeatures && (
            <Tab label={t("Events")} value="events" />
          )}
          {an_andonFeaturesEnabled && <Tab label={t("Andon")} value="andon" />}
        </Tabs>
      )}

      <Box
        id="tab-content"
        sx={{
          height: "100%",
          width: "98%",
          overflowX: "hidden",
          overflowY: "scroll"
        }}
      >
        {tabIndex === "settings" && <Settings />}
        {tabIndex === "workstation-config" && shouldEnableWorkstationTab && (
          <WorkstationConfig />
        )}
        {isAdmin && (
          <>
            {tabIndex === "batch-settings" && <BatchSettings />}
            {tabIndex === "bin-cleaning-schedule" &&
              isBinCleaningTabEnabled && <CleaningBinSchedule />}
            {tabIndex === "grid-settings" && <GridSettings viewTitle="" />}
            {tabIndex === "status" && <Status />}
          </>
        )}
        {tabIndex === "events" && an_displayEventFeatures && <Events />}
        {tabIndex === "andon" && an_andonFeaturesEnabled && <Andon />}
      </Box>
    </Box>
  );
}

export default SettingsView;
