import {
  canCycleCount,
  canDecant,
  canPick,
  getUserClientId,
  isClientAdmin
} from "~/api/usersTypes/auth0Profile";
import { StoreState } from "~/redux/reducers";

export const selectIsUserLoggedIn = (state: StoreState) =>
  state.login.isUserLoggedIn;
export const selectAuth0Profile = (state: StoreState) => state.login.profile;
export const selectAuth0UserId = (state: StoreState) =>
  state.login.profile?.userId;
export const selectUsersClientId = (state: StoreState) =>
  state.login.profile ? getUserClientId(state.login.profile) : null;
export const selectUsersProfileName = (state: StoreState) =>
  state.login.profile ? state.login.profile?.name : null;
export const selectUsersRoleString = (state: StoreState) =>
  state.login.profile ? state.login.profile.roles.join(".") : null;
export const selectUserCanPick = (state: StoreState) =>
  state.login.profile && canPick(state.login.profile);
export const selectUserCanCycleCount = (state: StoreState) =>
  state.login.profile && canCycleCount(state.login.profile);
export const selectUserCanDecant = (state: StoreState) =>
  state.login.profile && canDecant(state.login.profile);
export const selectUserIsAdmin = (state: StoreState) =>
  state.login.profile && isClientAdmin(state.login.profile);
