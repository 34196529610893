import { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { LoadingBackdrop } from "~/components/LoadingBackDrop";
import useFlag from "~/config/flags";
import { useToast } from "~/hooks/useToast";
import { mixpanelTrack } from "~/lib/mixpanel-tracking";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import {
  clearFulfillmentCenter,
  fetchFulfillmentCenter
} from "~/redux/actions/store";
import { sendUserEventAction } from "~/redux/actions/userEvents";
import { StoreState } from "~/redux/reducers";

import { useLazyGetAllFulfillmentCentersQuery } from "~/redux/warehouse/fulfillmentCenter.hooks";

import { handleAuthCallback } from "./login.slice";
import mixpanel from "mixpanel-browser";
import { selectAuth0UserId } from "~/redux/selectors/authSelectors";

const authCallbackTimeout = 3000;

const mapStateToProps = (state: StoreState) => ({
  fulfillmentCenterId: state.workstations.siteFulfillmentCenterId
});

const connector = connect(mapStateToProps, {
  fetchFulfillmentCenter,
  clearFulfillmentCenter,
  sendUserEventAction
});

type PropsFromRedux = ConnectedProps<typeof connector>;
type AuthCallBackProps = PropsFromRedux;

function AuthCallback(props: AuthCallBackProps) {
  const {
    fulfillmentCenterId,
    fetchFulfillmentCenter,
    clearFulfillmentCenter,
    sendUserEventAction
  } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { errorToast } = useToast();

  const [getAllFulfillmentCenters] = useLazyGetAllFulfillmentCentersQuery();

  const error = useAppSelector((state) => state.login.errorMessage);

  const isBevClientLogoutDecouplingEnabled =
    useFlag().bevClientLogoutFlowDecoupling;

  const currentUserId = useAppSelector(selectAuth0UserId);
  useEffect(() => {
    if (currentUserId) mixpanel.identify(currentUserId);
  }, [currentUserId]);

  useEffect(() => {
    void (async () => {
      await dispatch(handleAuthCallback());

      mixpanelTrack({
        trackedPageName: "Login",
        type: "Success",
        label: "Login Success"
      });

      // send login event
      await sendUserEventAction(
        "UserLoggedIn",
        isBevClientLogoutDecouplingEnabled
      );

      try {
        // validate fc
        // fetch all fcs for site and use to validate user's fc (duplicate which could be removed with careful timing)
        const clientFcs = await getAllFulfillmentCenters().unwrap();

        const clientFcIds =
          (clientFcs && clientFcs.map((fc) => fc.fulfillmentCenterId)) || [];

        // fulfillmentCenterId comes from store.workstations.siteFulfillmentCenterId
        // this is set when a user has already selected a fulfillment center
        // so if the user has a valid fc, we fetch it again and set it in the store
        if (fulfillmentCenterId && clientFcIds.includes(fulfillmentCenterId)) {
          // hits `/api/fulfillment-centers/${fulfillmentCenterId}`
          // puts the user's fc in the store
          // puts the appropriate batch filters into the batch store, used throughout the app
          await fetchFulfillmentCenter(fulfillmentCenterId);
        } else {
          // clears the fc center and batch filters
          clearFulfillmentCenter();
        }
      } catch (err) {
        errorToast(getMessageFromRtkError(err));
      }
      navigate("/");
    })();
  }, [
    clearFulfillmentCenter,
    dispatch,
    fetchFulfillmentCenter,
    fulfillmentCenterId,
    getAllFulfillmentCenters,
    errorToast,
    isBevClientLogoutDecouplingEnabled,
    navigate,
    sendUserEventAction
  ]);

  // track login slice error and redirect
  useEffect(() => {
    if (error) {
      mixpanelTrack({
        trackedPageName: "Login",
        type: "Error",
        label: "Login Failed"
      });
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  // track and redirect timeout
  useEffect(() => {
    const timer = setTimeout(() => {
      mixpanelTrack({
        trackedPageName: "Login",
        type: "Error",
        label: "Login Timed Out"
      });
      // redirect after X seconds
      navigate("/");
    }, authCallbackTimeout);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingBackdrop open />;
}

export default connector(AuthCallback);
