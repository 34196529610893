import { Box, CircularProgress, Stack, Typography } from "@mui/material";

import { skipToken } from "@reduxjs/toolkit/query";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { SplitButton } from "~/components/SplitButton";
import { setSelectedAutostoreGridId } from "~/features/status/status.slice";

import { selectCells } from "~/redux/selectors/logPublisherSelectors";
import { selectStatusSelectedAutostoreGridId } from "~/redux/selectors/statusSelectors";
import {
  useGetAutostoreGridsQuery,
  useGetLogPublisherStateQuery
} from "~/redux/warehouse/autostoreGrid.hooks";

import { BinCell } from "./BinCell";
import { CellHighlight } from "./CellHighlight";
import { PortCell } from "./PortCell";

export const GridView = () => {
  const dispatch = useAppDispatch();
  const { data: autostoreGrids } = useGetAutostoreGridsQuery();
  const selectedAutostoreGridId = useAppSelector(
    selectStatusSelectedAutostoreGridId
  );

  const { cells, columns, isFetching } = useGetLogPublisherStateQuery(
    selectedAutostoreGridId || skipToken,
    {
      selectFromResult: ({ data, isFetching }) => ({
        ...selectCells(data),
        isFetching
      })
    }
  );

  const template = `repeat(${columns}, 25px)`;

  return (
    <>
      <Stack alignItems={"flex-end"} py={2}>
        <SplitButton
          selectedValue={selectedAutostoreGridId}
          options={(autostoreGrids || []).map((autostoreGrid) => ({
            label: autostoreGrid.autostoreGridName,
            value: autostoreGrid.autostoreGridId
          }))}
          clickCb={(value) => dispatch(setSelectedAutostoreGridId(value))}
          switchCb={(value) => dispatch(setSelectedAutostoreGridId(value))}
        />
      </Stack>
      {isFetching && <CircularProgress />}
      {!isFetching && !cells && <Typography>No Data Found</Typography>}
      {!isFetching && cells && (
        <>
          <Box display="grid" gridTemplateColumns={template}>
            {cells?.map((c) => {
              return c.isPort ? (
                <PortCell key={`${c.x}/${c.y}`} position={c} />
              ) : (
                <BinCell key={`${c.x}/${c.y}`} position={c} />
              );
            })}
          </Box>
          <CellHighlight />
        </>
      )}
    </>
  );
};
