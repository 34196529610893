import { Container, Paper, Stack, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import BinCleaning from "~/components/settings/BinCleaning";
import BinFillCapacity from "~/components/settings/BinFillCapacity";
import BinMovements from "~/components/settings/BinMovements";
import BinRemoved from "~/components/settings/BinRemoved";
import { useGridSelector } from "~/features/inventory/useGridSelector";
import { useNavbar } from "~/hooks/useNavbar";
import { useView } from "~/hooks/useView";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";

type Props = {
  viewTitle: string;
};

const StyledPaper = styled(Paper)({
  border: "1px solid black",
  borderRadius: "10",
  marginBottom: "20px"
});

export function GridSettings(props: Props) {
  const { viewTitle } = props;
  const { t } = useTranslation();
  const clientConfig = useAppSelector(selectClientConfig);
  const binFlaggingEnabled = useAppSelector(
    (state) => state.store.usersFulfillmentCenter?.binFlaggingEnabled
  );
  const { ap_binRemovedFlow } = clientConfig;

  useView({ permanentSidenav: true });
  useNavbar({ viewTitle });
  const { gridSelectorButton } = useGridSelector();

  return (
    <Container>
      <Stack direction="row" justifyContent="space-between" mb={4}>
        <Typography variant="h6" margin="20px 0">
          {t("grid settings")}
        </Typography>
        {gridSelectorButton}
      </Stack>
      <StyledPaper>
        <BinFillCapacity />
      </StyledPaper>
      {binFlaggingEnabled && (
        <>
          <StyledPaper>
            <BinCleaning />
          </StyledPaper>
          <StyledPaper>
            <BinMovements />
          </StyledPaper>
        </>
      )}
      {ap_binRemovedFlow && (
        <StyledPaper>
          <BinRemoved />
        </StyledPaper>
      )}
    </Container>
  );
}
