import { RecurringScheduleDto } from "~/types/api";

import { warehouseApi } from "./warehouseApi";

export const recurringScheduleApi = warehouseApi.injectEndpoints({
  endpoints: (build) => ({
    getRecurringSchedules: build.query<RecurringScheduleDto[], void>({
      query: () => ({
        url: `/api/recurring-schedules`,
        method: "GET"
      }),
      providesTags: ["recurring schedule"]
    })
  }),
  overrideExisting: false
});
