import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import { useAppDispatch } from "~/app/store";
import { useDevCheats } from "~/hooks/useDevCheats";
import { useNavbar } from "~/hooks/useNavbar";
import { useTabNavLocation } from "~/hooks/useTabNavLocation";
import { useView } from "~/hooks/useView";

import { resetSiteWorkstationStatus } from "~/redux/actions/workstations";

import { useLazyGetWorkstationsQuery } from "~/redux/warehouse/workstation.hooks";

import EventExplorer from "./EventExplorer";
import { clearAndonWorkstationsAndGrids, clearEventData } from "./andon.slice";

type EventsInheritedProps = { viewTitle?: string };
type PropsFromRedux = ConnectedProps<typeof connector>;
type EventsProps = EventsInheritedProps & PropsFromRedux;

const connector = connect(null, {
  resetSiteWorkstationStatus
});

function Events(props: EventsProps) {
  const { viewTitle, resetSiteWorkstationStatus } = props;
  const [triggerFetchWorkstations] = useLazyGetWorkstationsQuery();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useView({ fixedHeight: true, permanentSidenav: true });
  useDevCheats({ showEventSimulator: true });
  const { setMenuItems } = useNavbar({ viewTitle });

  useEffect(() => {
    setMenuItems([
      {
        textContent: "Clear Andon Statuses and Fetch Workstations",
        actionCb: () => {
          dispatch(clearAndonWorkstationsAndGrids());
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
          triggerFetchWorkstations();
          resetSiteWorkstationStatus();
        }
      },
      {
        textContent: "Clear Events",
        actionCb: () => {
          dispatch(clearEventData());
        }
      }
    ]);
  }, [
    setMenuItems,
    resetSiteWorkstationStatus,
    dispatch,
    triggerFetchWorkstations
  ]);

  const { tabIndex, handleTabIndexChange } = useTabNavLocation({
    defaultTab: "event-explorer",
    validTabs: ["event-explorer"],
    key: "events-tab"
  });

  return (
    <>
      <Box style={{ width: "100%", height: "100%" }}>
        <Box
          id="tabs-and-buttons"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Tabs
            value={tabIndex}
            onChange={(_event, newValue) => {
              handleTabIndexChange(newValue as string);
            }}
            aria-label="events tabs"
            sx={{
              mt: 1,
              mb: 2,
              ml: 2
            }}
          >
            <Tab label={t("event explorer")} value="event-explorer" />
          </Tabs>
        </Box>
        <Box
          id="tab-content"
          sx={{
            height: "90%",
            width: "100%",
            overflowX: "hidden"
          }}
        >
          {/* tab: event explorer */}
          {tabIndex === "event-explorer" && <EventExplorer />}
        </Box>
      </Box>
    </>
  );
}

export default connector(Events);
