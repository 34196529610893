import { Route, Routes, createBrowserRouter } from "react-router-dom";

import envConstants from "~/config/envConstants";
import Analytics from "~/features/analytics/Analytics";
import AutostoreCycleCounts from "~/features/autostoreCycleCounts/AutostoreCycleCounts";
import AutostoreInventoryHolds from "~/features/autostoreInventoryHolds/AutostoreInventoryHolds";
import { AutostoreMain } from "~/features/autostoreMain/AutostoreMain";
import AutostorePicking from "~/features/autostorePicking/AutostorePicking";
import PickingPreviousBin from "~/features/autostorePreviousBin/AutostorePreviousBin";
import AutostorePutawayV2 from "~/features/autostorePutaway/AutostorePutawayV2";
import AutostorePutawayOld from "~/features/autostorePutawayOld/AutostorePutawayOld";
import Batch from "~/features/batch/Batch";
import BatchCartPrep from "~/features/batch/BatchCartPrep";
import { Batches as AdminBatches } from "~/features/batches/AdminBatches";
import { BinMaintenanceWorkstation } from "~/features/binMaintenanceWorkstation/BinMaintenanceWorkstation";
import { BinReconfiguration } from "~/features/binReconfiguration/BinReconfiguration";
import { StartBinReconfiguration } from "~/features/binReconfiguration/StartBinReconfiguration";
import { Carts } from "~/features/carts/Carts";
import CleaningBin from "~/features/cleaningBin/CleaningBin";
import CleaningBinView from "~/features/cleaningBin/CleaningBinView";
import { CreateBatches } from "~/features/createBatches/CreateBatches";
import CycleCount from "~/features/cycleCounts/CycleCount";
import CycleCounts from "~/features/cycleCounts/CycleCounts";
import CycleCountTask from "~/features/cycleCounts2023/CycleCountTask";
import CycleCountsV2 from "~/features/cycleCounts2023/CycleCountsV2";
import { DemoPage } from "~/features/demoPage/DemoPage";
import ManualOps from "~/features/home/ManualOps";
import InventoryMainV2 from "~/features/inventory/InventoryMainV2";
import { InventoryHolds } from "~/features/inventoryHolds/InventoryHolds";
import InventoryOld from "~/features/inventoryOld/InventoryOld";
import AuthCallback from "~/features/login/AuthCallback";
import { BevClientLogin } from "~/features/login/BevClientLogin";
import { Login } from "~/features/login/Login";
import { Logout } from "~/features/login/Logout";
import OrganizationLogin from "~/features/login/OrganizationLogin";
import LowInventory from "~/features/lowInventory/LowInventory";
import ManageFlaggedInventory from "~/features/manageFlaggedInventory/ManageFlaggedInventory";
import { OrderCreation } from "~/features/orderCreation/OrderCreation";
import { Orders } from "~/features/orders/Orders";
import PickBatches from "~/features/pickBatches/PickBatches";
import Placeholder from "~/features/placeholder/Placeholder";
import Putaway from "~/features/putaway/Putaway";
import PutawayTask from "~/features/putaway/PutawayTask";
import SettingsView from "~/features/settings/SettingsView";
import { Ship } from "~/features/ship/Ship";
import { ShipOrderTable } from "~/features/shipOrder/ShipOrderTable";
import StageTotes from "~/features/stage/StageTotes";
import { DevCheatsProvider } from "~/hooks/useDevCheats";
import { NavbarProvider } from "~/hooks/useNavbar";
import { SideNavProvider } from "~/hooks/useSideNav";
import { ViewProvider } from "~/hooks/useView";
import { isHomePageTypeIncluded } from "~/lib/helpers";

import { selectClientConfig } from "~/redux/selectors/siteSelectors";

import { ContextReset } from "./ContextReset";
import { Layout } from "./Layout";
import { LoginLayout } from "./LoginLayout";
import { PrivateRoute } from "./PrivateRoute";
import { RootLayout } from "./RootLayout";
import { useAppSelector } from "./store";
import { ErrorBoundary } from "~/components/ErrorBoundary";

export const Root = () => {
  const clientConfig = useAppSelector(selectClientConfig);
  const { dev_orderCreation, binCleaningEnabled, fc_autostore_homepages } =
    clientConfig;

  // contains routes that use the <Layout> component for rendering View and Navbar components
  const layoutRoutes = [
    {
      path: "/",
      component: <ManualOps viewTitle="nav.link.manual ops" />
    },
    {
      path: "/admin-batches",
      component: <AdminBatches viewTitle="nav.viewname.admin batches" />
    },
    ...(isHomePageTypeIncluded(fc_autostore_homepages, "cycle counts")
      ? [
          {
            path: "/autostore-cyclecount",
            component: (
              <AutostoreCycleCounts viewTitle="nav.viewname.autostore cyclecounts" />
            )
          }
        ]
      : []),
    {
      path: "/autostore-inventory-holds",
      component: (
        <AutostoreInventoryHolds viewTitle="nav.viewname.autostore inventory holds" />
      )
    },
    {
      path: "/autostore-main",
      component: <AutostoreMain viewTitle="nav.viewname.autostore" />
    },
    {
      path: "/autostore-pick",
      component: <AutostorePicking viewTitle="nav.viewname.autostore pick" />
    },
    ...(isHomePageTypeIncluded(fc_autostore_homepages, "induction v2")
      ? [
          {
            path: "/autostore-putaway-v2",
            component: (
              <AutostorePutawayV2 viewTitle="nav.viewname.autostore induction" />
            )
          }
        ]
      : [
          {
            path: "/autostore-putaway",
            component: (
              <AutostorePutawayOld viewTitle="nav.viewname.autostore induction" />
            )
          }
        ]),
    {
      path: "/batches/:batchName/cart-prep",
      component: <BatchCartPrep viewTitle="nav.viewname.cart prep" />
    },
    {
      path: "/batches/:batchId/pick",
      component: <Batch viewTitle="nav.viewname.batch - pick tasks" />
    },
    {
      path: "/batches/:batchName/stage",
      component: <StageTotes viewTitle="nav.viewname.batches - staging areas" />
    },
    {
      path: "/carts",
      component: <Carts viewTitle="nav.viewname.carts" />
    },
    {
      path: "/create-batches",
      component: <CreateBatches viewTitle="nav.viewname.create batches" />
    },
    {
      path: "/cycle-count/:cycleCountId",
      component: <CycleCount viewTitle="nav.viewname.cycle count" />
    },
    {
      path: "/cycle-count",
      component: <CycleCounts viewTitle="nav.viewname.cycle counts" />
    },
    {
      path: "/demo",
      component: <DemoPage viewTitle="" />
    },
    {
      path: "/analytics",
      component: <Analytics viewTitle="Analytics" />
    },
    ...(isHomePageTypeIncluded(fc_autostore_homepages, "inventory")
      ? [
          {
            path: "/inventory",
            component: <InventoryOld viewTitle="nav.viewname.inventory" />
          },
          {
            path: "/inventory/product",
            component: <InventoryOld viewTitle="nav.viewname.inventory" />
          },
          {
            path: "/inventory/product/:variantId",
            component: (
              <InventoryOld viewTitle="nav.viewname.inventory control - product" />
            )
          },
          {
            path: "/inventory/bin",
            component: <InventoryOld viewTitle="nav.viewname.inventory" />
          },
          {
            path: "/inventory/bin/:binId",
            component: (
              <InventoryOld viewTitle="nav.viewname.inventory control - bin" />
            )
          }
        ]
      : []),
    {
      path: "/inventory-holds",
      component: <InventoryHolds viewTitle="nav.link.inventory holds" />
    },
    {
      path: "/low-inventory",
      component: <LowInventory viewTitle="nav.viewname.low inventory" />
    },
    {
      path: "/orders",
      component: <Orders viewTitle="nav.viewname.orders" />
    },
    {
      path: "/pick-batches",
      component: <PickBatches viewTitle="nav.viewname.pick batches" />
    },
    {
      path: "/picking-previous-bin",
      component: <PickingPreviousBin />
    },
    {
      path: "/putaway",
      component: <Putaway viewTitle="nav.viewname.putaway task_plural" />
    },
    {
      path: "/putaway/:variantId",
      component: <PutawayTask viewTitle="nav.viewname.putaway task" />
    },
    {
      path: "/settings",
      component: <SettingsView viewTitle="nav.viewname.settings" />
    },
    {
      path: "/ship/consolidation",
      component: <Ship viewTitle="nav.viewname.consolidation" />
    },
    {
      path: "/ship/order/:orderId",
      component: <ShipOrderTable viewTitle="nav.viewname.order" />
    },
    {
      path: "/start-bin-reconfiguration",
      component: (
        <StartBinReconfiguration viewTitle="nav.viewname.start bin reconfiguration" />
      )
    },
    {
      path: "/bin-reconfiguration",
      component: (
        <BinReconfiguration viewTitle="nav.viewname.bin reconfiguration" />
      )
    },
    {
      path: "/users",
      component: <Placeholder viewTitle="nav.viewname.users" />
    }
  ];

  if (isHomePageTypeIncluded(fc_autostore_homepages, "cycle counts v2")) {
    layoutRoutes.push({
      path: "/autostore-cyclecountv2",
      component: <CycleCountsV2 />
    });

    if (isHomePageTypeIncluded(fc_autostore_homepages, "cycle counts v2")) {
      layoutRoutes.push({
        path: "/autostore-cyclecountv2/cycle-count-task",
        component: <CycleCountTask />
      });
    }
  }

  if (binCleaningEnabled) {
    layoutRoutes.push({
      path: "/bin-maintenance",
      component: <CleaningBin viewTitle="nav.viewname.bin maintenance" />
    });
    layoutRoutes.push({
      path: "/view-bin-maintenance",
      component: <CleaningBinView viewTitle="nav.viewname.bin maintenance" />
    });
  }

  //inventoryv2 routes
  layoutRoutes.push(
    {
      path: "/inventoryv2/holds",
      component: <InventoryMainV2 viewTitle="nav.viewname.inventory holds" />
    },
    {
      path: "/inventoryv2",
      component: <InventoryMainV2 viewTitle="nav.viewname.inventory" />
    },
    {
      path: "/inventoryv2/product",
      component: <InventoryMainV2 viewTitle="nav.viewname.inventory" />
    },
    {
      path: "/inventoryv2/product/:variantId",
      component: (
        <InventoryMainV2 viewTitle="nav.viewname.inventory control - product" />
      )
    },
    {
      path: "/inventoryv2/bin",
      component: <InventoryMainV2 viewTitle="nav.viewname.inventory" />
    },
    {
      path: "/inventoryv2/bin/:binNumber",
      component: (
        <InventoryMainV2 viewTitle="nav.viewname.inventory control - bin" />
      )
    },
    {
      path: "/inventoryv2/manage-flagged",
      component: <ManageFlaggedInventory viewTitle="manage flagged bins" />
    }
  );

  // 3 port maintenance - in development
  layoutRoutes.push({
    path: "/workstation-bin-maintenance",
    component: (
      <BinMaintenanceWorkstation viewTitle="nav.viewname.bin maintenance" />
    )
  });

  if (envConstants.DEV_CHEATS_UAT === "true" || dev_orderCreation) {
    layoutRoutes.push({
      path: "/order-creation",
      component: <OrderCreation viewTitle="Order Creation" />
    });
  }

  return (
    <ErrorBoundary>
      <Routes>
        <Route element={<RootLayout />}>
          {/* login routes */}
          <Route element={<LoginLayout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/login/:name" element={<OrganizationLogin />} />
            <Route path="/login/pepsi" element={<BevClientLogin />} />
          </Route>
          <Route
            path="/logout"
            element={
              <PrivateRoute>
                <Logout />
              </PrivateRoute>
            }
          />
          <Route path="/callback" element={<AuthCallback />} />
          {/* routes that use the Layout to render view and navbar */}
          <Route
            element={
              <ViewProvider>
                <NavbarProvider>
                  <SideNavProvider>
                    <DevCheatsProvider>
                      <Layout />
                    </DevCheatsProvider>
                  </SideNavProvider>
                </NavbarProvider>
              </ViewProvider>
            }
          >
            {layoutRoutes.map((info) => (
              <Route
                key={info.path}
                path={info.path}
                element={
                  <PrivateRoute>
                    <ContextReset key={info.path}>
                      {info.component}
                    </ContextReset>
                  </PrivateRoute>
                }
              />
            ))}
          </Route>
        </Route>
      </Routes>
    </ErrorBoundary>
  );
};

export const router = createBrowserRouter([{ path: "*", element: <Root /> }]);
