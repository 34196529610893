import {
  Box,
  InputLabel,
  Stack,
  TextField,
  Paper,
  Container
} from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import WorkstationRepresentation from "~/components/autostore/workstationConfig/WorkstationRepresentation";
import { useNavbar } from "~/hooks/useNavbar";
import { useSetThisWorkstation } from "~/hooks/useSetThisWorkstation";
import { useView } from "~/hooks/useView";
import { selectUserIsAdmin } from "~/redux/selectors/authSelectors";
import { selectDropdownWorkstation } from "~/redux/selectors/workstationConfigSelectors";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import { useGetWorkstationsQuery } from "~/redux/warehouse/workstation.hooks";
import { WorkstationSummaryDto } from "~/types/api";

import { AddWorkstation } from "./AddWorkstation";
import { PortSetup } from "./PortSetup";
import { SetAsWorkstationButton } from "./SetAsWorkstationButton";
import { TotePlacements } from "./TotePlacements";
import { WorkstationHeader } from "./WorkstationHeader";
import { WorkstationMultiportCheckbox } from "./WorkstationMultiportCheckbox";
import { WorkstationOrderTypes } from "./WorkstationOrderTypes";
import { WorkstationOrientation } from "./WorkstationOrientation";
import { WorkstationRoles } from "./WorkstationRoles";
import { useWorkstationConfigForm } from "./useWorkstationConfigForm";
import { setDropdownWorkstationId } from "./workstationConfig.slice";
import { WorkstationSize } from "./workstationConstants";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";

type Props = {
  viewTitle?: string;
};

export function WorkstationConfig(props: Props) {
  const { viewTitle } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const setThisWorkstation = useSetThisWorkstation();
  useView({ permanentSidenav: true });

  const [workstationSizes, setWorkstationSizes] = useState<WorkstationSize[]>(
    []
  );
  const siteWorkstation = useAppSelector(selectThisWorkstation);
  const isAdmin = useAppSelector(selectUserIsAdmin);
  const workstation = useAppSelector(selectDropdownWorkstation);

  const { data: fetchedWorkstations, error: getWorkstationsError } =
    useGetWorkstationsQuery();
  if (getWorkstationsError) {
    throw new Error(getMessageFromRtkError(getWorkstationsError));
  }

  const form = useWorkstationConfigForm({
    viewTitle
  });
  useNavbar({ viewTitle });
  const {
    register,
    formState: { isDirty }
  } = form;

  const calculateWorkstationCoordinates = (ws: WorkstationSummaryDto) => {
    const minX = Math.min(
      ...ws.ports.map((port) => port.coordinate.x),
      ...ws.totePlacements.map((tp) => tp.coordinate.x)
    );
    const maxX = Math.max(
      ...ws.ports.map((port) => port.coordinate.x),
      ...ws.totePlacements.map((tp) => tp.coordinate.x)
    );
    const minY = Math.min(
      ...ws.ports.map((port) => port.coordinate.y),
      ...ws.totePlacements.map((tp) => tp.coordinate.y)
    );
    const maxY = Math.max(
      ...ws.ports.map((port) => port.coordinate.y),
      ...ws.totePlacements.map((tp) => tp.coordinate.y)
    );

    setWorkstationSizes((wsSizes) =>
      wsSizes.concat({
        workstationId: ws.id,
        xCoords: Array.from({ length: maxX - minX + 1 }, (_, i) => i + minX),
        yCoords: Array.from({ length: maxY - minY + 1 }, (_, i) => i + minY)
      })
    );
  };

  // Load the current or default workstation into the dropdown
  useEffect(() => {
    if (fetchedWorkstations?.length) {
      dispatch(
        setDropdownWorkstationId(
          siteWorkstation?.id || fetchedWorkstations[0]?.id
        )
      );
      fetchedWorkstations.forEach((ws) => calculateWorkstationCoordinates(ws));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedWorkstations]);

  // If workstation is updated, reselect the workstation for the user
  useEffect(() => {
    const updateWorkstation = async () => {
      const updatedThisWorkstation = fetchedWorkstations?.find(
        (ws: WorkstationSummaryDto) => ws.id === siteWorkstation?.id
      );
      if (
        updatedThisWorkstation &&
        siteWorkstation !== updatedThisWorkstation
      ) {
        await setThisWorkstation(updatedThisWorkstation, false).catch(
          (error) => {
            console.error("Failed to update workstation", error);
          }
        );
      }
    };
    void updateWorkstation();
  }, [setThisWorkstation, fetchedWorkstations, siteWorkstation]);

  const getSquareState = (args: {
    workstationId: string;
    x: number;
    y: number;
  }) => {
    const { workstationId, x, y } = args;

    const matchingWorkstation = fetchedWorkstations?.find(
      (workstation) => workstation.id === workstationId
    );

    if (!matchingWorkstation) return null;

    if (
      matchingWorkstation.ports.some(
        (port) => port.coordinate.x === x && port.coordinate.y === y
      )
    ) {
      return "port";
    }

    if (x === 0 && y === 0) {
      return "user";
    }

    const matchingTotePosition = matchingWorkstation.totePlacements.find(
      (totePlacement) =>
        totePlacement.coordinate.x === x && totePlacement.coordinate.y === y
    );

    if (matchingTotePosition) return "tote";

    return null;
  };

  const representationClick = (args: {
    workstationId: string;
    x: number;
    y: number;
  }) => {
    const { workstationId, x, y } = args;

    if (getSquareState({ workstationId, x, y }) === null) {
      // console.log(`add ${x}, ${y} to totePositions`);
    }

    if (getSquareState({ workstationId, x, y }) === "tote") {
      // console.log(`remove ${x}, ${y} from totePositionns`);
    }
  };

  if (!workstation) {
    return null;
  }

  return (
    <Container style={{ maxWidth: "unset" }} sx={{ mt: 2 }}>
      <Stack spacing={4}>
        <Paper sx={{ padding: 2.5 }}>
          <FormProvider {...form}>
            <form autoComplete="off">
              <Box>
                <WorkstationHeader />
                <Box
                  display="grid"
                  gridTemplateColumns={
                    workstation.roles.length > 2
                      ? "1fr 1fr .8fr 1.8fr 1fr"
                      : "1fr 1fr .8fr 1.8fr 1fr"
                  }
                  mt={3}
                  gap={2.5}
                  alignItems="flex-end"
                >
                  <Box>
                    <InputLabel htmlFor="deviceId" shrink>
                      {t("workstation")}
                    </InputLabel>
                    <TextField
                      id="deviceId"
                      fullWidth
                      variant="standard"
                      {...register("deviceId")}
                      disabled={!isAdmin}
                    />
                  </Box>

                  <Box>
                    <InputLabel htmlFor="autostore-grid" shrink>
                      {t("autostore grid")}
                    </InputLabel>
                    <TextField
                      id="autostore-grid"
                      variant="standard"
                      fullWidth
                      value={workstation.autostoreGridName}
                      disabled
                    />
                  </Box>

                  <WorkstationOrientation />
                  <WorkstationRoles />
                  <WorkstationMultiportCheckbox />
                  <WorkstationOrderTypes />
                </Box>

                <Stack
                  mt={5}
                  direction="row"
                  flexWrap="wrap"
                  gap={4}
                  justifyContent="space-between"
                >
                  <PortSetup />

                  <TotePlacements />

                  <Stack justifyContent="center" alignItems="center">
                    <WorkstationRepresentation
                      size={80}
                      rows={
                        workstationSizes.find(
                          (w) => w.workstationId === workstation.id
                        )?.yCoords || []
                      }
                      columns={
                        workstationSizes.find(
                          (w) => w.workstationId === workstation.id
                        )?.xCoords || []
                      }
                      operatorCoordinate={{
                        x: 0,
                        y: 0
                      }}
                      portCoordinates={workstation.ports}
                      totePlacements={workstation.totePlacements}
                      onClickCb={(args) => {
                        const { x, y } = args;
                        representationClick({
                          x,
                          y,
                          workstationId: workstation.id
                        });
                      }}
                    />
                  </Stack>
                </Stack>

                <SetAsWorkstationButton />
              </Box>
            </form>
          </FormProvider>
        </Paper>

        {!isDirty && isAdmin && <AddWorkstation />}
      </Stack>
    </Container>
  );
}
