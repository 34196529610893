import CheckIcon from "@mui/icons-material/Check";
import {
  Typography,
  Grid,
  InputAdornment,
  TextField,
  CircularProgress,
  IconButton,
  styled
} from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";

import { percentRegex } from "~/lib/helpers";
import { selectUsersClientId } from "~/redux/selectors/authSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import {
  useGetBinConfigurationsQuery,
  usePatchBinConfigurationMutation
} from "~/redux/warehouse/bin.hooks";
import { AutostoreBinConfigurationDto } from "~/types/api";

type AutostoreBinConfigurationDtoExtended = AutostoreBinConfigurationDto & {
  newMaxBinFillPercent?: string;
  isDirty?: boolean;
  hasError?: boolean;
};

const numberOfCompartmentsToBinTypeMap: Record<number, string> = {
  1: "whole bins",
  2: "half bins",
  4: "quarter bins"
};

const CheckmarkButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.dark,
  backgroundColor: "#0000000a",
  width: "55px",
  height: "55px",
  borderRadius: "10px"
}));

function BinFillCapacity() {
  const { t } = useTranslation();
  const usersFulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);
  const usersClientId = useAppSelector(selectUsersClientId);
  const [modifiedBinConfigurationsById, setModifiedBinConfigurationsById] =
    useState<Record<Guid, AutostoreBinConfigurationDtoExtended>>({});

  const { isLoading, data: binConfigurations } = useGetBinConfigurationsQuery(
    usersClientId && usersFulfillmentCenter ? undefined : skipToken
  );

  const [patch, { isLoading: isPatching }] = usePatchBinConfigurationMutation();

  const onPercentChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    {
      configurationId,
      maxBinFillPercent
    }: Pick<
      AutostoreBinConfigurationDto,
      "configurationId" | "maxBinFillPercent"
    >
  ) => {
    const { value } = event.target;
    setModifiedBinConfigurationsById((prevState) => ({
      ...prevState,
      [configurationId]: {
        ...prevState[configurationId],
        isDirty: true,
        maxBinFillPercent,
        newMaxBinFillPercent: value
      }
    }));
  };

  const onUpdateMaxFillPercent = async (configurationId: string) => {
    const { maxBinFillPercent, newMaxBinFillPercent } =
      modifiedBinConfigurationsById[configurationId];
    const isValidNumber =
      newMaxBinFillPercent && percentRegex.test(newMaxBinFillPercent);
    if (isValidNumber && +newMaxBinFillPercent !== maxBinFillPercent) {
      await patch({
        configurationId,
        maxBinFillPercent: +newMaxBinFillPercent
      }).unwrap();
      setModifiedBinConfigurationsById((prevState) => ({
        ...prevState,
        [configurationId]: {
          ...prevState[configurationId],
          isDirty: false,
          hasError: false
        }
      }));
    } else {
      setModifiedBinConfigurationsById((prevState) => ({
        ...prevState,
        [configurationId]: {
          ...prevState[configurationId],
          hasError: true
        }
      }));
    }
  };

  return isLoading ? (
    <Grid container justifyContent="center" sx={{ margin: "20px 0" }}>
      <CircularProgress size={30} />
    </Grid>
  ) : (
    <Grid container p="50px" justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography sx={{ fontSize: "19px" }}>
          {t("bin fill capacity")}
        </Typography>
      </Grid>
      <Grid
        item
        container
        justifyContent="flex-end"
        width="unset"
        gap="50px"
        alignItems="center"
      >
        {binConfigurations?.map(
          ({ configurationId, numberOfCompartments, maxBinFillPercent }) => (
            <Grid item container key={configurationId} width="unset" gap="4px">
              <Grid item>
                <TextField
                  data-testid="bin-fill-capacity-input"
                  disabled={isPatching}
                  label={t(
                    numberOfCompartmentsToBinTypeMap[numberOfCompartments]
                  )}
                  onChange={(event) =>
                    onPercentChange(event, {
                      configurationId,
                      maxBinFillPercent
                    })
                  }
                  value={
                    modifiedBinConfigurationsById[configurationId]?.isDirty
                      ? modifiedBinConfigurationsById[configurationId]
                          .newMaxBinFillPercent
                      : maxBinFillPercent
                  }
                  sx={{ width: "160px" }}
                  error={
                    modifiedBinConfigurationsById[configurationId]?.hasError
                  }
                  helperText={
                    modifiedBinConfigurationsById[configurationId]?.hasError
                      ? "Must be number 1-100"
                      : null
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item minWidth="55px" sx={{ margin: "1px 0 0 5px" }}>
                {modifiedBinConfigurationsById[configurationId]?.isDirty && (
                  <CheckmarkButton
                    disabled={isPatching}
                    onClick={() => onUpdateMaxFillPercent(configurationId)}
                  >
                    <CheckIcon />
                  </CheckmarkButton>
                )}
              </Grid>
            </Grid>
          )
        )}
      </Grid>
    </Grid>
  );
}

export default BinFillCapacity;
