import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useEffect, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { useNavbar } from "~/hooks/useNavbar";
import { useTabNavLocation } from "~/hooks/useTabNavLocation";
import { useView } from "~/hooks/useView";
import { selectStatusSelectedAutostoreGridId } from "~/redux/selectors/statusSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import { selectWorkstationAutostoreGridId } from "~/redux/selectors/workstationsSelectors";
import { useGetAutostoreGridsQuery } from "~/redux/warehouse/autostoreGrid.hooks";

import { FcConfigList } from "./FcConfigList";
import { LaunchDarklyFlags } from "./LaunchDarklyFlags";
import PTLStatus from "./PTLStatus";
import { PortStatus } from "./PortStatus";
import { SystemStatus } from "./SystemStatus";
import { TaskAssessment } from "./TaskAssessment";
import { GridView } from "./gridView/GridView";

import { setSelectedAutostoreGridId } from "./status.slice";

type StatusProps = { viewTitle?: string };

const StatusTab = ({
  selectedTab,
  tabIndex,
  children
}: { selectedTab: string; tabIndex: string } & PropsWithChildren) => {
  return (
    <Box
      role="tabpanel"
      hidden={tabIndex !== selectedTab}
      id={`tabpanel-${tabIndex}`}
      aria-labelledby={`tab-${tabIndex}`}
    >
      {tabIndex === selectedTab && children}
    </Box>
  );
};

export const Status = (props: StatusProps) => {
  const { viewTitle } = props;

  const { t } = useTranslation();

  const usersFulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);
  const selectedAutostoreGridId = useAppSelector(
    selectStatusSelectedAutostoreGridId
  );
  const workstationAutostoreGridId = useAppSelector(
    selectWorkstationAutostoreGridId
  );
  const { data: autostoreGrids } = useGetAutostoreGridsQuery();
  const dispatch = useAppDispatch();

  useView({ fixedHeight: true, permanentSidenav: true });
  useNavbar({ viewTitle });

  const validTabs = [
    "task-assessment",
    "system-status",
    "ports-status",
    "ptl-status",
    "grid-view",
    "fc-config",
    "launch-darkly-flags"
  ];
  const { tabIndex, handleTabIndexChange } = useTabNavLocation({
    defaultTab: "task-assessment",
    validTabs,
    key: "status-tab"
  });

  useEffect(() => {
    if (!selectedAutostoreGridId && autostoreGrids?.length) {
      dispatch(
        setSelectedAutostoreGridId(
          workstationAutostoreGridId || autostoreGrids[0].autostoreGridId
        )
      );
    }
  }, [
    autostoreGrids,
    workstationAutostoreGridId,
    selectedAutostoreGridId,
    dispatch
  ]);

  const a11yProps = (index: string) => {
    return {
      id: `tab-${index}`,
      value: index,
      "aria-controls": `tabpanel-${index}`
    };
  };

  return (
    <>
      <Stack>
        <Tabs
          value={tabIndex}
          onChange={(_event, newValue) => {
            handleTabIndexChange(newValue as string);
          }}
          aria-label="status tabs"
          sx={{
            mt: 1,
            mb: 2,
            ml: 2
          }}
        >
          {usersFulfillmentCenter?.pickingConfigurations.includes(
            "Autostore"
          ) && (
            <Tab
              label={t("task assessment")}
              {...a11yProps("task-assessment")}
            />
          )}
          {usersFulfillmentCenter?.pickingConfigurations.includes(
            "Autostore"
          ) && (
            <Tab label={t("system status")} {...a11yProps("system-status")} />
          )}
          {usersFulfillmentCenter?.pickingConfigurations.includes(
            "Autostore"
          ) && (
            <Tab
              label={`${t("port")} ${t("status")}`}
              {...a11yProps("ports-status")}
            />
          )}
          {usersFulfillmentCenter?.pickingConfigurations.includes(
            "Autostore"
          ) && (
            <Tab label={`PTL ${t("status")}`} {...a11yProps("ptl-status")} />
          )}
          {usersFulfillmentCenter?.pickingConfigurations?.includes(
            "Autostore"
          ) && <Tab label={`Grid View`} {...a11yProps("grid-view")} />}
          <Tab label={`FC ${t("config")}`} {...a11yProps("fc-config")} />
          <Tab
            label={`${t("Launch Darkly flags")}`}
            {...a11yProps("launch-darkly-flags")}
          />
        </Tabs>
        <Box p={2}>
          <StatusTab selectedTab={tabIndex} tabIndex="task-assessment">
            <TaskAssessment />
          </StatusTab>
          <StatusTab selectedTab={tabIndex} tabIndex="system-status">
            <SystemStatus />
          </StatusTab>
          <StatusTab selectedTab={tabIndex} tabIndex="ports-status">
            <PortStatus />
          </StatusTab>
          <StatusTab selectedTab={tabIndex} tabIndex="ptl-status">
            <PTLStatus />
          </StatusTab>
          <StatusTab selectedTab={tabIndex} tabIndex="grid-view">
            <GridView />
          </StatusTab>
          <StatusTab selectedTab={tabIndex} tabIndex="fc-config">
            <FcConfigList />
          </StatusTab>
          <StatusTab selectedTab={tabIndex} tabIndex="launch-darkly-flags">
            <LaunchDarklyFlags />
          </StatusTab>
        </Box>
      </Stack>
    </>
  );
};
