import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  TextField
} from "@mui/material";
import { t } from "i18next";

import { ModalDialogTransition } from "~/components/ModalDialogTransition";
import { dayOfWeekAsString } from "~/lib/shared";
import { RecurringScheduleDto } from "~/types/api";

function formatStartOfWindow(
  recurringSchedule: RecurringScheduleDto | null
): string {
  return recurringSchedule?.scheduleWindow?.[0] ?? "N/A";
}

function formatEndOfWindow(
  recurringSchedule: RecurringScheduleDto | null
): string {
  return recurringSchedule && recurringSchedule.scheduleWindow.length > 1
    ? recurringSchedule?.scheduleWindow[1]
    : "N/A";
}

export function RecurringSchedule(props: {
  recurringSchedule: RecurringScheduleDto | null;
  onClose: () => void;
}) {
  const { recurringSchedule, onClose } = props;

  const isOpen = !!recurringSchedule;

  const startOfWindow = formatStartOfWindow(recurringSchedule);
  const endOfWindow = formatEndOfWindow(recurringSchedule);

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={ModalDialogTransition}
      keepMounted
      onClose={onClose}
      maxWidth="sm"
      aria-labelledby="confirmation"
      aria-describedby="confirm"
    >
      <DialogTitle className="confirm-title">
        {`${
          recurringSchedule
            ? dayOfWeekAsString(recurringSchedule.dayOfWeek)
            : "N/A"
        } ${startOfWindow} - ${endOfWindow}`}
      </DialogTitle>
      <DialogContent>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                label="Interval"
                helperText="When interval expires, a new wave is generated"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">minutes</InputAdornment>
                  )
                }}
                InputLabelProps={{
                  shrink: true
                }}
                value={recurringSchedule?.interval}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                label="Size"
                helperText="Maximum number of orders to include in a wave"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">orders</InputAdornment>
                  )
                }}
                InputLabelProps={{
                  shrink: true
                }}
                value={recurringSchedule?.ordersPerWave}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormGroup row>
                  <FormControlLabel
                    control={<Checkbox disabled name="shipment" checked />}
                    label="Shipment"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            {/* TODO: add start/end controls, but finding the correct value is a mystery to me
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                label="Start Time"
                type="time"
                defaultValue={startOfWindow}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  step: 300 // 5 min
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                label="End Time"
                type="time"
                defaultValue={endOfWindow}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  step: 300 // 5 min
                }}
              />
            </Grid> */}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
